<script setup>
import { ref, onMounted, inject } from "vue";
import axios from "../axios";
import { storedata } from "../store/data";

const Swal = inject('$swal')

const users_edit = ref({});

async function getdatprofile() {
  const res = await axios.post(`usersprofile`, {
    id: storedata.id.slice(9),
  });
  if(res.data.status == 1){
    users_edit.value.username = res.data.datas.username;
    users_edit.value.name = res.data.datas.name;
    users_edit.value.type = res.data.datas.type;
    users_edit.value.status = res.data.datas.status;
  }
}

async function edit_profile() {
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอซักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  if(!users_edit.value.name){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกชื่อก่อน",
        icon: "warning"
    });
  }else{
    const res = await axios.post(`editprofile`,{
        id: storedata.id.slice(9),
        name: users_edit.value.name,
        password: users_edit.value.password,
    });
    if(res.data.status == 1){
      users_edit.value = {};
      getdatprofile();
      Swal.fire({
          title: 'สำเร็จ',
          text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
          icon: "success"
      });
    }else{
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "แก้ไขข้อมูลไม่สำเร็จ",
          icon: "error"
      });
    }
  }
}

onMounted(() => {
  getdatprofile()
})
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg-0 mb-4">
            <div class="card">
              <div class="card-header text-center">
                <h5>ข้อมูลผู้ใช้งาน</h5>
                <p>สามารถเปลี่ยนรหัสผ่านและชื่อผู้ใช้งานได้ที่นี่</p>
              </div>
              <div class="card-body">
                <div class="container">
                  <div class="row justify-content-md-center">
                    <div class="col-sm-4 mb-3">
                      <div class="mb-3">
                          <label for="exampleFormControlInpute1" class="form-label">ชื่อผู้ใช้งาน(Username)</label>
                          <input type="text" class="form-control" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="exampleFormControlInpute1" placeholder="กรุณากรอกชื่อผู้ใช้งาน(Username)" v-model="users_edit.username" disabled>
                        </div>
                        <div class="mb-3">
                          <label for="exampleFormControlInpute2" class="form-label">รหัสผ่าน(Password)</label>
                          <input type="password" class="form-control" id="exampleFormControlInpute2" placeholder="ในกรณีต้องการเปลี่ยนรหัสผ่านให้กรอกรหัสในช่อง แล้วกด บันทึกข้อมูล" v-model="users_edit.password">
                        </div>
                        <div class="mb-3">
                          <label for="exampleFormControlInpute3" class="form-label">ชื่อ</label>
                          <input type="text" class="form-control" id="exampleFormControlInpute3" placeholder="กรุณากรอกชื่อ" v-model="users_edit.name">
                        </div>
                        <div class="mb-3">
                          <label for="exampleFormControlInpute4" class="form-label">ประเภทผู้ใช้งาน</label>
                          <select class="form-select" style="font-size: 18px;" id="exampleFormControlInpute4" v-model="users_edit.type" disabled>
                            <option value="">กรุณาเลือกประเภทผู้ใช้งาน</option>
                            <option value="1">แอดมิน</option>
                            <option value="2">ซุปเปอร์แอดมิน</option>
                          </select>
                        </div>
                        <div class="mb-3">
                          <label for="exampleFormControlInpute5" class="form-label">สถานะ</label>
                          <select class="form-select" style="font-size: 18px;" id="exampleFormControlInpute5" v-model="users_edit.status" disabled>
                            <option value="">กรุณาเลือกสถานะ</option>
                            <option value="1">ใช้งานได้ปกติ</option>
                            <option value="2">โดนระงับการใช้งาน</option>
                          </select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-center">
                <button type="button" class="btn btn-primary" @click="edit_profile()">บันทึกข้อมูล</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
