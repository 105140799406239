<script setup >
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import { ref, onMounted, reactive, inject } from "vue";
import axios from "../axios";

const Swal = inject('$swal')

import { useStore } from "vuex";
const store = useStore();

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfmake);

import { Modal } from "bootstrap";
import readXlsxFile from 'read-excel-file'

const statuscar = ref([]);
const datacar = ref([]);
const car = ref({});
const car_edit = ref({});
const dataselect = ref();
const dataexcel = ref([]);

const state = reactive({
    modal_IN: null,
})
async function getdata_car(){
  const res = await axios.get(`carptt`);
  if(res.data.status == 1){
    let uc1 = 0;
    for (let i = 0; i < res.data.datas.length; i++) {
      uc1++;
      datacar.value.push([res.data.datas[i].id.slice(14),res.data.datas[i].card_id,res.data.datas[i].tb1,res.data.datas[i].tb2,new Date(res.data.datas[i].update_at).toLocaleString("en-GB").replace( /,/,"" )]);
    }
    statuscar.value = [uc1];
  }
}

onMounted(() => {
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  state.modal_IN = new Modal('#model-in', {})
  state.modal_ED = new Modal('#model-ed', {})
  state.modal_EC = new Modal('#model-ec', {})
  getdata_car();
  dataselect.value.dt.on('click', 'button', function (e) {
    let data = dataselect.value.dt.row(e.target.closest('tr')).data();
    get_edit_data(data)
  })
})


function openModalIN(){ state.modal_IN.show()}
function closeModalIN(){state.modal_IN.hide()}
function openModalEC(){ state.modal_EC.show()}
function closeModalEC(){state.modal_EC.hide()}
function openModalED(){ state.modal_ED.show()}
function closeModalED(){state.modal_ED.hide()}

async function add_data(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอซักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  if(!car.value.card_id){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอก CardID",
        icon: "warning"
    });
  }else if(!car.value.tb1){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกทะเบียนรถ",
        icon: "warning"
    });
  }else if(!car.value.tb2){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกจังหวัดทะเบียน",
        icon: "warning"
    });
  }else{
    const res = await axios.post(`insertcarptt`,{
        card_id: car.value.card_id,
        tb1: car.value.tb1,
        tb2: car.value.tb2,
    });
    if(res.data.status == 1){
      closeModalIN();
      datacar.value = [];
      statuscar.value = [];
      getdata_car();
      car.value.card_id = '';
      car.value.tb1 = '';
      car.value.tb2 = '';
      Swal.fire({
          title: 'สำเร็จ',
          text: "เพิ่มข้อมูลเรียบร้อยแล้ว",
          icon: "success"
      });
    }else if(res.data.status == 2){
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "ทะเบียนซ้ำ",
          icon: "error"
      });
    }else{
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "เพิ่มข้อมูลไม่สำเร็จ",
          icon: "error"
      });
    }
  }
}

function get_edit_data(data){
  car_edit.value.id = data[0];
  car_edit.value.card_id = data[1];
  car_edit.value.tb1 = data[2];
  car_edit.value.tb2 = data[3];
  openModalED()
}

async function edit_data(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอซักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  if(!car_edit.value.card_id){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอก CardID",
        icon: "warning"
    });
  }else if(!car_edit.value.tb1){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกทะเบียนรถ",
        icon: "warning"
    });
  }else if(!car_edit.value.tb2){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกจังหวัดทะเบียน",
        icon: "warning"
    });
  }else{
    const res = await axios.post(`editcarptt`,{
      card_id: car_edit.value.card_id,
      tb1: car_edit.value.tb1,
      tb2: car_edit.value.tb2,
      id: car_edit.value.id,
    });
    if(res.data.status == 1){
      closeModalED();
      datacar.value = [];
      statuscar.value = [];
      getdata_car();
      car_edit.value.card_id = '';
      car_edit.value.tb1 = '';
      car_edit.value.tb2 = '';
      Swal.fire({
          title: 'สำเร็จ',
          text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
          icon: "success"
      });
    }else if(res.data.status == 2){
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "ทะเบียนรถซ้ำ",
          icon: "error"
      });
    }else{
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "แก้ไขข้อมูลไม่สำเร็จ",
          icon: "error"
      });
    }
  }
  closeModalED()
}

function del_data(){
  if(dataselect.value.dt.rows({ selected: true }).count() >= 1){
    Swal.fire({
      title: "คำเตือน",
      text: "ต้องการลบข้อมูลที่เลือกหรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ต้องการลบ"
    }).then( async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอซักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        Swal.showLoading()
        let dataselectf = dataselect.value.dt.rows({ selected: true }).data();
        var idarray = [];
        for (let index = 0; index < dataselectf.length; index++) {
          idarray.push("'"+dataselectf[index][0]+"'");
        }
        const res = await axios.post(`del/carptt`,{
          idarray: idarray,
        });
        if(res.data.status == 1){
          datacar.value = [];
          statuscar.value = [];
          getdata_car();
          Swal.fire({
            title: "สำเร็จ",
            text: "ข้อมูลถูกลบเรียบร้อยแล้ว",
            icon: "success"
          });
        }else{
          Swal.fire({
            title: "ไม่สำเร็จ",
            text: "เกิดข้อผิดพลาด",
            icon: "error"
          });
        }
      }
    });
  }else{
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณาเลือกข้อมูลที่ต้องการลบในตารางก่อน !",
      icon: "error"
    });
  }
}

function onFileChange(event) {
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอซักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    let xlsxfile = event.target.files ? event.target.files[0] : null;
    readXlsxFile(xlsxfile).then((rows) => {
      const input = {
        "columnNames": [
          "card_id",
          "tb1",
          "tb2",
          "update_at"
        ],
        "rows": rows
      };
      const output = {
        reportResults: input.rows.map(row => Object.fromEntries(
          input.columnNames.map((name, i) => [name, row[i]?row[i].toString():new Date().toISOString()])
        ))
      };
      dataexcel.value = output.reportResults;
      console.log(dataexcel.value)
      Swal.close() ;
    })
  }

function add_data_ec() {
  if(dataexcel.value.length >= 1){
    Swal.fire({
      title: "คำเตือน",
      text: "ต้องการนำเข้าข้อมูลจากไฟล์ Excel หรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ต้องการนำเข้า"
    }).then( async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอซักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        Swal.showLoading()
        const res = await axios.post(`importexcelcarptt`,{
          dataexcel: dataexcel.value,
        });
        if(res.data.status == 1){
          closeModalEC()
          datacar.value = [];
          statuscar.value = [];
          dataexcel.value = [];
          getdata_car();
          Swal.fire({
            title: "สำเร็จ",
            text: "ข้อมูลถูกลบเรียบร้อยแล้ว",
            icon: "success"
          });
        }else{
          Swal.fire({
            title: "ไม่สำเร็จ",
            text: "เกิดข้อผิดพลาด",
            icon: "error"
          });
        }
      }
    });
  }else{
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณาเลือกไฟล์ Excel ก่อน หรือไฟล์ Excel ไม่สามารถใช้งานได้",
      icon: "warning"
    });
  }
}

function clear_data_ec() {
  Swal.fire({
    title: "คำเตือน",
    text: "ต้องการลบข้อมูลทั้งหมดหรือไม่ ?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "ต้องการลบ"
  }).then( async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอซักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()
      const res = await axios.post(`cleardatacarptt`);
      if(res.data.status == 1){
        closeModalEC()
        datacar.value = [];
        statuscar.value = [];
        dataexcel.value = [];
        getdata_car();
        Swal.fire({
          title: "สำเร็จ",
          text: "ข้อมูลถูกลบเรียบร้อยแล้ว",
          icon: "success"
        });
      }else{
        Swal.fire({
          title: "ไม่สำเร็จ",
          text: "เกิดข้อผิดพลาด",
          icon: "error"
        });
      }
    }
  });
}

const options = ref({
  dom: '<"top"<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {
          extend: 'copyHtml5',
          text: '<i class="fas fa-file-alt text-lg"></i>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<i class="fas fa-file-excel text-lg"></i>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<i class="fas fa-file-csv text-lg"></i>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<i class="fas fa-file-pdf text-lg"></i>',
          titleAttr: 'PDF'
      }
  ],
  pageLength: 10,
  responsive: true,
  select: {
        style: 'multi',
        selector: 'td:not(:nth-child(7))',
    },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ],
  order: [[1, 'desc']],
  language: dataTextTH,
  columnDefs: [
        {
            data: null,
            defaultContent: '<button type="button" class="btn btn-warning btn-sm"">แก้ไข</button>',
            targets: -1
        }
    ]
});
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนรถที่ได้สิทธิส่วนลดทั้งหมด"
              :value= "{
                text: statuscar[0],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>100%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-truck',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          
          
        </div>
        <!-- model  -->
        <div class="modal fade" id="model-in" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">เพิ่มข้อมูลรถ</b>
                        <button type="button" @click="closeModalIN()" class="close-btn">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 mb-3">
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">CardID</label>
                            <input type="text" class="form-control" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="exampleFormControlInput1" placeholder="กรุณากรอก CardID" v-model="car.card_id">
                          </div>
                          <div class="mb-3">
                            <label for="exampleFormControlInput2" class="form-label">ทะเบียนรถ</label>
                            <input type="text" class="form-control" id="exampleFormControlInput2" onkeypress="return /[0-9a-zA-Zก-ฎฐ-๙]/i.test(event.key)" maxlength="30" placeholder="กรุณากรอกทะเบียนรถ" v-model="car.tb1">
                          </div>
                          <div class="mb-3">
                            <label for="exampleFormControlInput3" class="form-label">จังหวัดทะเบียน</label>
                            <select id="exampleFormControlInput3" class="form-select" v-model="car.tb2">
                                <option value="" selected>เลือกจังหวัด</option>
                                <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                                <option value="กระบี่">กระบี่</option>
                                <option value="กาญจนบุรี">กาญจนบุรี</option>
                                <option value="กาฬสินธุ์">กาฬสินธุ์</option>
                                <option value="กำแพงเพชร">กำแพงเพชร</option>
                                <option value="ขอนแก่น">ขอนแก่น</option>
                                <option value="จันทบุรี">จันทบุรี</option>
                                <option value="ฉะเชิงเทรา">ฉะเชิงเทรา</option>
                                <option value="ชัยนาท">ชัยนาท</option>
                                <option value="ชัยภูมิ">ชัยภูมิ</option>
                                <option value="ชุมพร">ชุมพร</option>
                                <option value="ชลบุรี">ชลบุรี</option>
                                <option value="เชียงใหม่">เชียงใหม่</option>
                                <option value="เชียงราย">เชียงราย</option>
                                <option value="ตรัง">ตรัง</option>
                                <option value="ตราด">ตราด</option>
                                <option value="ตาก">ตาก</option>
                                <option value="นครนายก">นครนายก</option>
                                <option value="นครปฐม">นครปฐม</option>
                                <option value="นครพนม">นครพนม</option>
                                <option value="นครราชสีมา">นครราชสีมา</option>
                                <option value="นครศรีธรรมราช">นครศรีธรรมราช</option>
                                <option value="นครสวรรค์">นครสวรรค์</option>
                                <option value="นราธิวาส">นราธิวาส</option>
                                <option value="น่าน">น่าน</option>
                                <option value="นนทบุรี">นนทบุรี</option>
                                <option value="บึงกาฬ">บึงกาฬ</option>
                                <option value="บุรีรัมย์">บุรีรัมย์</option>
                                <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์</option>
                                <option value="ปทุมธานี">ปทุมธานี</option>
                                <option value="ปราจีนบุรี">ปราจีนบุรี</option>
                                <option value="ปัตตานี">ปัตตานี</option>
                                <option value="พะเยา">พะเยา</option>
                                <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา</option>
                                <option value="พังงา">พังงา</option>
                                <option value="พิจิตร">พิจิตร</option>
                                <option value="พิษณุโลก">พิษณุโลก</option>
                                <option value="เพชรบุรี">เพชรบุรี</option>
                                <option value="เพชรบูรณ์">เพชรบูรณ์</option>
                                <option value="แพร่">แพร่</option>
                                <option value="พัทลุง">พัทลุง</option>
                                <option value="ภูเก็ต">ภูเก็ต</option>
                                <option value="มหาสารคาม">มหาสารคาม</option>
                                <option value="มุกดาหาร">มุกดาหาร</option>
                                <option value="แม่ฮ่องสอน">แม่ฮ่องสอน</option>
                                <option value="ยโสธร">ยโสธร</option>
                                <option value="ยะลา">ยะลา</option>
                                <option value="ร้อยเอ็ด">ร้อยเอ็ด</option>
                                <option value="ระนอง">ระนอง</option>
                                <option value="ระยอง">ระยอง</option>
                                <option value="ราชบุรี">ราชบุรี</option>
                                <option value="ลพบุรี">ลพบุรี</option>
                                <option value="ลำปาง">ลำปาง</option>
                                <option value="ลำพูน">ลำพูน</option>
                                <option value="เลย">เลย</option>
                                <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                                <option value="สกลนคร">สกลนคร</option>
                                <option value="สงขลา">สงขลา</option>
                                <option value="สมุทรสาคร">สมุทรสาคร</option>
                                <option value="สมุทรปราการ">สมุทรปราการ</option>
                                <option value="สมุทรสงคราม">สมุทรสงคราม</option>
                                <option value="สระแก้ว">สระแก้ว</option>
                                <option value="สระบุรี">สระบุรี</option>
                                <option value="สิงห์บุรี">สิงห์บุรี</option>
                                <option value="สุโขทัย">สุโขทัย</option>
                                <option value="สุพรรณบุรี">สุพรรณบุรี</option>
                                <option value="สุราษฎร์ธานี">สุราษฎร์ธานี</option>
                                <option value="สุรินทร์">สุรินทร์</option>
                                <option value="สตูล">สตูล</option>
                                <option value="หนองคาย">หนองคาย</option>
                                <option value="หนองบัวลำภู">หนองบัวลำภู</option>
                                <option value="อำนาจเจริญ">อำนาจเจริญ</option>
                                <option value="อุดรธานี">อุดรธานี</option>
                                <option value="อุตรดิตถ์">อุตรดิตถ์</option>
                                <option value="อุทัยธานี">อุทัยธานี</option>
                                <option value="อุบลราชธานี">อุบลราชธานี</option>
                                <option value="อ่างทอง">อ่างทอง</option>
                            </select>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" @click="add_data()" class="btn btn-info border-radius-ptt" style="font-size: 20px;">บันทึกข้อมูล</button>
                        <button type="button" @click="closeModalIN()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- model  Excel import-->
        <div class="modal fade" id="model-ec" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">นำเข้าข้อมูลจากไฟล์ Excel</b>
                        <button type="button" @click="closeModalEC()" class="close-btn">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 mb-3">
                          <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">แนบไฟล์ Excel Files เวอร์ชั่นปี 2007+ (.xlsx) ที่ต้องการนำข้อมูลเข้า<br><a href="https://docs.google.com/spreadsheets/d/1g_iXEvhDnvI3d0AzKonimT52umFC11b4DrtFWWMVkNo/edit?usp=sharing" target="_blank" class="text-primary">คลิกเพื่อโหลดไฟล์ Excel Template ตัวอย่าง</a></label>
                            <input type="file" class="form-control" @change="onFileChange" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                          </div>
                          <p class="text-danger">การนำเข้าข้อมูลจากไฟล์ Excel จะเป็นการนำข้อมูลไปเพิ่มจากเดิมโดยระบบจะยังเก็บข้อมูลเดิมไว้ด้วย หากอยากเครียข้อมูลทั้งหมดให้กดที่ปุ่ม "เครียข้อมูลทั้งหมด"</p>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" @click="add_data_ec()" class="btn btn-info" style="font-size: 20px;" :disabled="dataexcel.length <= 0">นำเข้าข้อมูล</button>
                      <button type="button" @click="clear_data_ec()" class="btn btn-danger" style="font-size: 20px;">เครียข้อมูลทั้งหมด</button>
                      <button type="button" @click="closeModalEC()" class="btn btn-dark" style="font-size: 20px;">ปิด</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- model edit  -->
        <div class="modal fade" id="model-ed" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">แก้ไขข้อมูลผู้ใช้งาน</b>
                        <button type="button" @click="closeModalED()" class="close-btn">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 mb-3">
                          <div class="mb-3">
                              <label for="exampleFormControlInpute1" class="form-label">CardID</label>
                              <input type="text" class="form-control" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="exampleFormControlInpute1" placeholder="กรุณากรอก CardID" v-model="car_edit.card_id">
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInpute2" class="form-label">ทะเบียนรถ</label>
                              <input type="text" class="form-control" id="exampleFormControlInpute2" onkeypress="return /[0-9a-zA-Zก-ฎฐ-๙]/i.test(event.key)" maxlength="30" placeholder="กรุณากรอกทะเบียนรถ" v-model="car_edit.tb1">
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInpute3" class="form-label">จังหวัดทะเบียน</label>
                              <select id="exampleFormControlInpute3" class="form-select" v-model="car_edit.tb2">
                                  <option value="" selected>เลือกจังหวัด</option>
                                  <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                                  <option value="กระบี่">กระบี่</option>
                                  <option value="กาญจนบุรี">กาญจนบุรี</option>
                                  <option value="กาฬสินธุ์">กาฬสินธุ์</option>
                                  <option value="กำแพงเพชร">กำแพงเพชร</option>
                                  <option value="ขอนแก่น">ขอนแก่น</option>
                                  <option value="จันทบุรี">จันทบุรี</option>
                                  <option value="ฉะเชิงเทรา">ฉะเชิงเทรา</option>
                                  <option value="ชัยนาท">ชัยนาท</option>
                                  <option value="ชัยภูมิ">ชัยภูมิ</option>
                                  <option value="ชุมพร">ชุมพร</option>
                                  <option value="ชลบุรี">ชลบุรี</option>
                                  <option value="เชียงใหม่">เชียงใหม่</option>
                                  <option value="เชียงราย">เชียงราย</option>
                                  <option value="ตรัง">ตรัง</option>
                                  <option value="ตราด">ตราด</option>
                                  <option value="ตาก">ตาก</option>
                                  <option value="นครนายก">นครนายก</option>
                                  <option value="นครปฐม">นครปฐม</option>
                                  <option value="นครพนม">นครพนม</option>
                                  <option value="นครราชสีมา">นครราชสีมา</option>
                                  <option value="นครศรีธรรมราช">นครศรีธรรมราช</option>
                                  <option value="นครสวรรค์">นครสวรรค์</option>
                                  <option value="นราธิวาส">นราธิวาส</option>
                                  <option value="น่าน">น่าน</option>
                                  <option value="นนทบุรี">นนทบุรี</option>
                                  <option value="บึงกาฬ">บึงกาฬ</option>
                                  <option value="บุรีรัมย์">บุรีรัมย์</option>
                                  <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์</option>
                                  <option value="ปทุมธานี">ปทุมธานี</option>
                                  <option value="ปราจีนบุรี">ปราจีนบุรี</option>
                                  <option value="ปัตตานี">ปัตตานี</option>
                                  <option value="พะเยา">พะเยา</option>
                                  <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา</option>
                                  <option value="พังงา">พังงา</option>
                                  <option value="พิจิตร">พิจิตร</option>
                                  <option value="พิษณุโลก">พิษณุโลก</option>
                                  <option value="เพชรบุรี">เพชรบุรี</option>
                                  <option value="เพชรบูรณ์">เพชรบูรณ์</option>
                                  <option value="แพร่">แพร่</option>
                                  <option value="พัทลุง">พัทลุง</option>
                                  <option value="ภูเก็ต">ภูเก็ต</option>
                                  <option value="มหาสารคาม">มหาสารคาม</option>
                                  <option value="มุกดาหาร">มุกดาหาร</option>
                                  <option value="แม่ฮ่องสอน">แม่ฮ่องสอน</option>
                                  <option value="ยโสธร">ยโสธร</option>
                                  <option value="ยะลา">ยะลา</option>
                                  <option value="ร้อยเอ็ด">ร้อยเอ็ด</option>
                                  <option value="ระนอง">ระนอง</option>
                                  <option value="ระยอง">ระยอง</option>
                                  <option value="ราชบุรี">ราชบุรี</option>
                                  <option value="ลพบุรี">ลพบุรี</option>
                                  <option value="ลำปาง">ลำปาง</option>
                                  <option value="ลำพูน">ลำพูน</option>
                                  <option value="เลย">เลย</option>
                                  <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                                  <option value="สกลนคร">สกลนคร</option>
                                  <option value="สงขลา">สงขลา</option>
                                  <option value="สมุทรสาคร">สมุทรสาคร</option>
                                  <option value="สมุทรปราการ">สมุทรปราการ</option>
                                  <option value="สมุทรสงคราม">สมุทรสงคราม</option>
                                  <option value="สระแก้ว">สระแก้ว</option>
                                  <option value="สระบุรี">สระบุรี</option>
                                  <option value="สิงห์บุรี">สิงห์บุรี</option>
                                  <option value="สุโขทัย">สุโขทัย</option>
                                  <option value="สุพรรณบุรี">สุพรรณบุรี</option>
                                  <option value="สุราษฎร์ธานี">สุราษฎร์ธานี</option>
                                  <option value="สุรินทร์">สุรินทร์</option>
                                  <option value="สตูล">สตูล</option>
                                  <option value="หนองคาย">หนองคาย</option>
                                  <option value="หนองบัวลำภู">หนองบัวลำภู</option>
                                  <option value="อำนาจเจริญ">อำนาจเจริญ</option>
                                  <option value="อุดรธานี">อุดรธานี</option>
                                  <option value="อุตรดิตถ์">อุตรดิตถ์</option>
                                  <option value="อุทัยธานี">อุทัยธานี</option>
                                  <option value="อุบลราชธานี">อุบลราชธานี</option>
                                  <option value="อ่างทอง">อ่างทอง</option>
                              </select>
                          </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                      <button type="button" @click="edit_data()" class="btn btn-info border-radius-ptt" style="font-size: 20px;">บันทึกข้อมูล</button>
                        <button type="button" @click="closeModalED()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- table  -->
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg-0 mb-4">
            <div class="card">
              <div class="card-header text-center">
                <h5>ตารางข้อมูลรถที่ได้สิทธิส่วนลดทั้งหมด</h5>
                <p>สามารถเพิ่มข้อมูลโดยใช้ไฟล์ Excel ได้ โดยข้อมูลต้องอยู่ในรูปแบบตัวอย่างไฟล์ที่กำหนดซึ่งสามารถดาวน์โหลดตัวอย่างได้ที่ <a href="https://docs.google.com/spreadsheets/d/1g_iXEvhDnvI3d0AzKonimT52umFC11b4DrtFWWMVkNo/edit?usp=sharing" target="_blank" class="text-danger">คลิกเพื่อดาวน์โหลดตัวอย่างไฟล์ Excel</a> <br>(ในการลบข้อมูลสามารถคลิกที่ข้อมูลที่ต้องการลบได้พร้อมกันหลายๆข้อมูล)</p>
                <button class="btn btn-primary me-2" type="button" @click="openModalIN()">เพิ่มข้อมูล</button>
                <button class="btn btn-info me-2" type="button" @click="openModalEC()">นำเข้าข้อมูลจากไฟล์ Excel</button>
                <button class="btn btn-danger" type="button" @click="del_data()">ลบข้อมูล</button>
              </div>
              <div class="card-body">
                <DataTable :data="datacar" :options="options" class="table table-hover" width="100%" ref="dataselect">
                  <thead>
                      <tr>
                          <th>Hash ID</th>
                          <th>CardID</th>
                          <th>ทะเบียนรถ</th>
                          <th>จังหวัดทะเบียน</th>
                          <th>อัพเดทเมื่อ</th>
                          <th>จัดการ</th>
                      </tr>
                  </thead>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    </div>
  </div>
</template>

<style>
@import 'datatables.net-bs5';
@import 'datatables.net-buttons-bs5';
@import 'datatables.net-responsive-bs5';
@import 'datatables.net-select-bs5';
div.dt-container div.dt-length select {
    width: 4em;
}

table.dataTable th.dt-type-numeric {
  text-align: left;
}
table.dataTable td.dt-type-numeric {
  text-align: left;
}
.table-center {
  text-align: center;
}
div.dt-container div.dt-search input{
  width: 180px;
}
.pagination {
  justify-content: center !important;
}
@media only screen and (min-width: 768px) {
  .left-col {
      float: left;
      width: 40%;
  }
  .center-col {
      float: left;
      width: 25%;
  }
  .right-col {
      float: left;
      width: 35%;
  }
}
.page-link {
  width: 50px;
  height: 50px;
}
.close-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 26px;
}
</style>
