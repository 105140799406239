<script setup>
// import { computed } from "vue";
import { useRoute } from "vue-router";
// import { useStore } from "vuex";
import { storedata } from "../../store/data";

import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

// const store = useStore();
// const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
<div>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item" style="font-size: 20px;">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'Dashboard' ? 'active' : ''"
          :navText="'แดชบอร์ด'"
        >
          <template v-slot:icon>
            <i class="fas fa-tachometer-alt text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          ข้อมูลสิทธิส่วนลด
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/carptt"
          :class="getRoute() === 'CarPTT' ? 'active' : ''"
          :navText="'จัดการข้อมูลบัตรส่วนลดทั้งหมด'"
        >
          <template v-slot:icon>
            <i class="fas fa-tags text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/cardc"
          :class="getRoute() === 'CarDC' ? 'active' : ''"
          :navText="'จัดการข้อมูลรถที่ไม่ได้รับบัตรส่วนลด'"
        >
          <template v-slot:icon>
            <i class="fas fa-truck text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          ข้อมูลรถรอป้าย/ป้ายแดง
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/cartb"
          :class="getRoute() === 'CarTB' ? 'active' : ''"
          :navText="'จัดการข้อมูลรถรอป้าย/ป้ายแดง'"
        >
          <template v-slot:icon>
            <i class="fas fa-car-alt text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          ข้อมูลผู้ใช้งาน
        </h6>
      </li>
      <li v-if="storedata.type == 2" class="nav-item">
        <sidenav-item
          to="/user"
          :class="getRoute() === 'User' ? 'active' : ''"
          :navText="'จัดการผู้ใช้งาน'"
        >
          <template v-slot:icon>
            <i class="fa fa-users text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'Profile' ? 'active' : ''"
          :navText="'โปรไฟล์'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>


    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: 'Need Help?',
        description: 'Please check our docs',
        links: [
          {
            label: 'Documentation',
            route:
              'https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/',
            color: 'dark',
          },
          {
            label: 'Buy now',
            route:
              'https://www.creative-tim.com/product/vue-argon-dashboard-pro?ref=vadp',
            color: 'success',
          },
        ],
      }"
    />
  </div>
</div>
</template>

<style scoped>
.text-ptt-1-2-rem{
  font-size: 1.2rem;
}
</style>
