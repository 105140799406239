<script setup>
import { computed, ref, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import { storedata } from "../../store/data";

const store = useStore();
const layout = computed(() => store.state.layout);

let dataUserN = ref();
let dataUserT = ref();

onMounted(() => {
  dataUserN.value = storedata.name; 
  dataUserT.value = storedata.type == 1?'แอดมิน':'ซุปเปอร์แอดมิน'; 
})
onUpdated(() => {
  dataUserN.value = storedata.name; 
  dataUserT.value = storedata.type == 1?'แอดมิน':'ซุปเปอร์แอดมิน'; 
})
function logout(){
  localStorage.clear();
  window.location.href = './signin';
}
</script>
<template>
  <div
    v-show="layout !== 'landing'"
    class="card card-plain shadow-none"
    id="sidenavCard"
  >
    <div class="p-3 card-body text-center w-100 pt-0">
      <img
        class="w-50 mx-auto"
        src="@/assets/img/ptt/avatars/godji_ptt.png"
        alt="sidebar_illustration"
      />
      <h6 class="mb-0 text-dark up">{{ dataUserN }}</h6>
      <p class="text-xs font-weight-bold">{{ dataUserT }}</p>
    </div>
    <button
      @click="logout()"
      target="_blank"
      class="mb-3 btn btn-dark btn-sm w-100"
    >
      ออกจากระบบ
    </button>
  </div>
</template>
