<script setup>
import {  ref, inject } from "vue";
import { useRouter } from 'vue-router'
const router = useRouter()
import axios from "../axios";

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const Swal = inject('$swal')

const logindata = ref({});

async function login(){
  if(!logindata.value.username){
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณากรอกชื่อผู้ใช้งาน",
      icon: "warning"
    });
  }else if(!logindata.value.password){
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณากรอกรหัสผ่าน",
      icon: "warning"
    });
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอซักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    const res = await axios.post(`login`,{
        username: logindata.value.username,
        password: logindata.value.password,
    });
    if(res.data.status == 1){
      localStorage.setItem('data', res.data.datas.data1)
      Swal.fire({
        title: "เข้าสู่ระบบสำเร็จ",
        text: "กดปุ่ม ตกลง เพื่อไปยังหน้าแดชบอร์ด",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ตกลง"
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({ path: '/dashboard' })
        }else{
          router.push({ path: '/dashboard' })
        }
      });
    }else if(res.data.status == 4){
      Swal.fire({
        title: "ไม่สำเร็จ",
        text: "สถานะผู้ใช้งานผู้ระงับ",
        icon: "error"
      });
    }else{
      Swal.fire({
        title: "ไม่สำเร็จ",
        text: "ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง",
        icon: "error"
      });
    }
  }
}
</script>
<template>
  <main class="main-content mt-0">
    
    <div class="page-header align-items-start min-vh-60 pt-5 pb-11 m-3 border-radius-lg body-bg">
      <span class="mask opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h2 class="text-primari mt-5">ระบบตรวจสอบข้อมูลบัตรส่วนลด<br>และทะเบียนรถ ในระบบ CNG</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>กรอกข้อมูลให้ครบถ้วนก่อนปุ่ม "เข้าสุ่ระบบ"</h5>
            </div>
            <div class="card-body">

                <argon-input
                  type="text"
                  placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                  aria-label="Name"
                  v-model="logindata.username"
                />
                <argon-input
                  type="password"
                  placeholder="กรุณากรอกรหัสผ่าน"
                  aria-label="Password"
                  v-model="logindata.password"
                />
                <div class="text-center">
                  <argon-button
                    fullWidth
                    color="info"
                    variant="gradient"
                    class="my-4 mb-2"
                    @click="login()"
                    >เข้าสุ่ระบบ</argon-button
                  >
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

</template>

<style>
.body-bg {
    padding: 0px;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('@/assets/img/ptt/system/ptt-qr-bg.jpg');
}
</style>
