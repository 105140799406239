import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:8002/api/auth/',
  baseURL: 'https://api.pttngvsafetycampaign.com/api/auth/',
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const public_token = localStorage.getItem('public_token');
  if (token) {
    config.headers.Authorization = token;
  }
  if(public_token){
    config.headers.Publictoken = public_token;
  }
  return config;
});

export default instance;
