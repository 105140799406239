<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import axios from "./axios";

const get_token = async () => {
  try {
    const response = await axios.get("token");
    localStorage.setItem('public_token', response.data)
  } catch (err) {
    console.log(err);
  }
}


const store = useStore();
const isNavFixed = computed(() => store.state.isNavFixed);
const darkMode = computed(() => store.state.darkMode);
const isAbsolute = computed(() => store.state.isAbsolute);
const showSidenav = computed(() => store.state.showSidenav);
const layout = computed(() => store.state.layout);
const showNavbar = computed(() => store.state.showNavbar);
const showFooter = computed(() => store.state.showFooter);

const navClasses = computed(() => {
  return {
    "position-sticky bg-white left-auto top-2 z-index-sticky":
      isNavFixed.value && !darkMode.value,
    "position-sticky bg-default left-auto top-2 z-index-sticky":
      isNavFixed.value && darkMode.value,
    "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
    "px-0 mx-4": !isAbsolute.value,
  };
});

onMounted(() => {
  get_token()
})
</script>
<template>
  <div>
    <div
    v-show="layout == 'landing'"
    class="h-100 position-fixed w-100 body-bg"
  ></div>

    <sidenav v-if="showSidenav" />

    <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    >
      <!-- nav -->

      <navbar :class="[navClasses]" v-if="showNavbar" />

      <router-view />

      <app-footer v-show="showFooter" />

      <!-- <configurator
        :toggle="toggleConfigurator"
        :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
      /> -->
    </main>
  </div>
</template>

<style>
.body-bg {
    padding: 0px;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('@/assets/img/ptt/system/ptt-qr-bg.jpg');
}
</style>
