import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/Profile.vue";
import User from "../views/User.vue";
import Signin from "../views/Signin.vue";
import Home from "../views/Home.vue";
import CarTB from "../views/CarTB.vue";
import CarDC from "../views/CarDC.vue";
import CarPTT from "../views/CarPTT.vue";
import axios from "../axios";
import { storedata } from "../store/data";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: "/cartb",
    name: "CarTB",
    component: CarTB,
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: "/cardc",
    name: "CarDC",
    component: CarDC,
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: "/carptt",
    name: "CarPTT",
    component: CarPTT,
    meta:{
      requiresAuth:true,
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to) => {
  try {
    const authenticated = await is_authenticated();
    if (to.meta.requiresAuth && !authenticated.status) {
      return { path: "/signin" };
    }
    if ((to.path === "/signin") && authenticated.status) {
      return { path: "/dashboard" };
    }
    if ((to.path === "/user") && authenticated.status && storedata.type != 2) {
      return { path: "/dashboard" };
    }
  } catch (err) {
    console.log('server is down');
  }
});

async function is_authenticated() {
  if(localStorage.getItem('data')){
    try {
      const response = await axios.post("/authenticated",{
        token1 : localStorage.getItem('data')
      });
      if(response.data.status == 1){
        storedata.id = response.data.datas.id;
        storedata.name = response.data.datas.name;
        storedata.type = response.data.datas.type;
        storedata.UrlImages = 'https://api.pttngvsafetycampaign.com/public/images/';
        // storedata.UrlImages = 'http://localhost:8002/public/images/';
        return{
          status : true,
        }
      }
    } catch (err) {
      return{
        status : false,
      }
    }
  }else{
    return{
      status : false,
    }
  }
}

export default router;
