<script setup>
import { onBeforeMount, ref, onMounted, reactive, inject } from "vue";
import { useStore } from "vuex";
import { QrcodeStream, QrcodeCapture } from 'vue-qrcode-reader';
import axios from "../axios";
import { Modal } from "bootstrap";

const Swal = inject('$swal')

const state = reactive({
    modal_DIS: null,
    modal_TB: null,
})


onMounted(() => {
    state.modal_DIS = new Modal('#model-dis', {})
    state.modal_TB = new Modal('#model-tb', {})
})

function openModalDIS(){ state.modal_DIS.show()}
function closeModalDIS(){state.modal_DIS.hide()}
function openModalTB(){state.modal_TB.show()}
function closeModalTB(){state.modal_TB.hide()}

const store = useStore();
onBeforeMount(() => {
  store.state.layout = "landing";
});

function fileToDataURL(file) {
    return new Promise(function(resolve,reject){
        let fr = new FileReader();
        fr.onload = function(){
            resolve(fr.result);
        };
        fr.onerror = function(){
            reject(fr);
        };
        fr.readAsDataURL(file);
    });
}
function readAsDataURLdata (data2,data3) {  
    var filesArray = [data2,data3];
    return Promise.all(filesArray.map(fileToDataURL))
}

var typeScanDC = ref(0);
var typeScanTB = ref(0);
const onDetect = (detectedCodes) => {
    if(detectedCodes.length >= 1){
        let qrvalue;
        if(Number(detectedCodes[0].rawValue)){
            qrvalue = detectedCodes[0].rawValue;
            
        }else{
            const regex = /code=(.+)/m;

            const str = detectedCodes[0].rawValue;
            let m;

            if ((m = regex.exec(str)) !== null) {
                qrvalue = m[1];
            }
        }
        Swal.fire({
            title: 'กรุณารอซักครู่...',
            html: 'ระบบกำลังตรวจสอบข้อมูล',
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                try {
                    const response = await axios.get(`checkdc/${qrvalue}`);
                    closeModalDIS();
                    if(response.data.status == 1){
                        Swal.fire({
                            title: response.data.title,
                            text: response.data.datas,
                            icon: "success"
                        });
                    }else if(response.data.status == 2){
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: response.data.datas,
                            icon: "error"
                        });
                    }else if(response.data.status == 3){
                        Swal.fire({
                            title: response.data.title,
                            text: response.data.datas,
                            icon: "error"
                        });
                    }else if(response.data.status == 4){
                        Swal.fire({
                            title: "ไม่พบข้อมูล",
                            showDenyButton: true,
                            icon: "warning",
                            iconColor: "#f27474",
                            text: response.data.datas,
                            confirmButtonText: "ถัดไป",
                            denyButtonText: "ปิด",
                            showCancelButton: false,
                            }).then((result) => {
                            if (result.isConfirmed) {
                                (async () => {
                                    const { value: formValues } = await Swal.fire({
                                        customClass: {
                                            input: 'form-select text-ptt-18',
                                        },
                                        showCancelButton: true,
                                        cancelButtonText: "ยกเลิก",
                                        confirmButtonText: "ส่งข้อมูล",
                                        title: "แนบรูปบัตรส่วนลด NGV",
                                        html: `
                                            <div class="mb-3">
                                                <label for="formFile" class="form-label">เบอร์โทรศัพท์</label>
                                                <input inputmode="numeric" oninput="this.value = this.value.replace(/\\D+/g, '')" maxlength="10" class="form-control text-ptt-18 text-center" id="input-tel-value" placeholder="กรุณากรอกเบอร์โทรศัพท์(ไม่เกิน 10 หลัก)">
                                            </div>
                                            <div>
                                                <label for="formFile" class="form-label">ไฟล์รูปบัตรส่วนลด (บัตรเขียว/บัตรเหลือง) (ขนาดไม่เกิน 3 MB) หากบัตรสูญหายกรุณาติดต่อ Line OA@PTTNGV</label>
                                                <input type="file" class="form-control text-ptt-18" id="input-image-value" placeholder="คลิกเพื่อแนบไฟล์รูป (บัตรเขียว/บัตรเหลือง)" accept="image/*"> 
                                            </div>
                                            `,
                                        preConfirm: () => {
                                            const data1Value = document.getElementById('input-tel-value').value;
                                            const data2Value = document.getElementById('input-image-value').files[0];
                                            if(!data1Value || !data2Value){
                                                Swal.showValidationMessage(`กรุณากรอกข้อมูลและแนบไฟล์รูปให้ครบถ้วน`)
                                            }
                                            return {
                                                data1 : data1Value,
                                                data2 : data2Value
                                            }
                                        }
                                    });
                                    if (formValues) {
                                        if(!formValues.data1){
                                            Swal.fire({
                                                title: 'เกิดข้อผิดพลาด',
                                                text: 'กรุณากรอกเบอร์โทรศัพท์',
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: true,
                                                denyButtonText: "ปิด",
                                            }).then((result) => {
                                                if (result.isDenied) {
                                                    Swal.close();
                                                }
                                            });
                                        }else if(!formValues.data2){
                                            Swal.fire({
                                                title: 'เกิดข้อผิดพลาด',
                                                text: 'กรุณาแนบไฟล์รูป',
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: true,
                                                denyButtonText: "ปิด",
                                            }).then((result) => {
                                                if (result.isDenied) {
                                                    Swal.close();
                                                }
                                            });
                                        }else{
                                            if(formValues.data2.size / 1024 / 1024 <= 3 && formValues.data2.type.split('/')[0] === "image" ){
                                                const reader = new FileReader();
                                                reader.onload = async (e) => {
                                                    const res = await axios.post(`uploaddis`,{
                                                        image_b64: e.target.result,
                                                        tb1: response.data.tb_1,
                                                        tb2: response.data.tb_2,
                                                        idv6: response.data.idv6,
                                                        carnum: response.data.carnum,
                                                        tel: formValues.data1,
                                                    });
                                                    if(res.data.status == 1){
                                                        Swal.fire({
                                                            title: res.data.title,
                                                            text: res.data.datas,
                                                            icon: "success"
                                                        });
                                                    }else if(res.data.status == 2){
                                                        Swal.fire({
                                                            title: 'ไม่สำเร็จ',
                                                            text: res.data.datas,
                                                            icon: "error"
                                                        });
                                                    }else if(res.data.status == 3){
                                                        Swal.fire({
                                                            title: 'ไม่สำเร็จ',
                                                            text: res.data.datas,
                                                            icon: "error"
                                                        });
                                                    }
                                                };
                                                reader.readAsDataURL(formValues.data2);
                                            }else{
                                                Swal.fire({
                                                    title: "คำเตือน !",
                                                    text: 'รูปที่แนบมามีขนาดเกิน 3 MB หรือไม่ใช่ไฟล์รูปภาพ',
                                                    icon: "error"
                                                });
                                            }
                                        }
                                    }else{
                                        Swal.close();
                                    }
                                })()
                            } else if (result.isDenied) {
                                Swal.close();
                            }
                        });
                    }else if(response.data.status == 5){
                        Swal.fire({
                            title: response.data.title,
                            text: response.data.datas,
                            icon: "success"
                        });
                    }else if(response.data.status == 6){
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: response.data.datas,
                            icon: "error"
                        });
                    }
                } catch (err) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด !',
                        text: err,
                        icon: "error"
                    });
                }
                
            }
            });
    }else{
        Swal.fire({
            title: "ตรวจสอบข้อมูลไม่สำเร็จ",
            text: "กรุณาเปลี่ยนรูปหรือใช้รูปอื่นที่มีความละเอียดชัดเจนมากกว่า หรือติดต่อ  Line OA@PTTNGV  พร้อมแจ้ง \"สแกนข้อมูลไม่สำเร็จ\" ต่อเจ้าหน้าที่",
            icon: "error"
        });
    }
}

const onDetectTB = (detectedCodes) => {
    if(detectedCodes.length >= 1){
        let qrvalue;
        if(Number(detectedCodes[0].rawValue)){
            qrvalue = detectedCodes[0].rawValue;
            
        }else{
            const regex = /code=(.+)/m;

            const str = detectedCodes[0].rawValue;
            let m;

            if ((m = regex.exec(str)) !== null) {
                qrvalue = m[1];
            }
        }
        Swal.fire({
            title: 'กรุณารอซักครู่...',
            html: 'ระบบกำลังตรวจสอบข้อมูล',
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                try {
                    const response = await axios.get(`checktb/${qrvalue}`);
                    closeModalTB();
                    if(response.data.status == 1){
                        Swal.fire({
                            title: response.data.title,
                            text: response.data.datas,
                            icon: "success"
                        });
                    }else if(response.data.status == 2){
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: response.data.datas,
                            icon: "error"
                        });
                    }else if(response.data.status == 3){
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: response.data.datas,
                            icon: "error"
                        });
                    }else if(response.data.status == 4){
                        Swal.fire({
                            title: "กรุณากรอกข้อมูลทะเบียนรถ",
                            showDenyButton: true,
                            icon: "warning",
                            iconColor: "#f27474",
                            text: response.data.datas,
                            confirmButtonText: "\"ถัดไป\"",
                            denyButtonText: "ปิด",
                            showCancelButton: false,
                            }).then((result) => {
                            if (result.isConfirmed) {
                                (async () => {
                                    const { value: formValues } = await Swal.fire({
                                        customClass: {
                                            input: 'form-select text-ptt-18',
                                        },
                                        showCancelButton: true,
                                        cancelButtonText: "ยกเลิก",
                                        confirmButtonText: "ส่งข้อมูล",
                                        title: "แนบรูปบัตรส่วนลด NGV",
                                        html: `
                                            <div class="mb-3">
                                                <label for="formFile" class="form-label">เบอร์โทรศัพท์</label>
                                                <input inputmode="text" onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" class="form-control text-ptt-18 text-center" id="input-tel-value" placeholder="กรุณากรอกเบอร์โทรศัพท์(ไม่เกิน 10 หลัก)">
                                            </div>
                                            <div class="mb-3">
                                                <label for="formFile" class="form-label">เลขทะเบียน</label>
                                                <input inputmode="text" onkeypress="return /[0-9a-zA-Zก-ฎฐ-๙]/i.test(event.key)" maxlength="30" class="form-control text-ptt-18 text-center" id="input-tb1-value" placeholder="กรุณากรอกเลขทะเบียน">
                                            </div>
                                            <div class="mb-3">
                                                <label for="formFile" class="form-label">จังหวัดทะเบียน</label>
                                                <select id="input-tb2-value" class="form-select text-ptt-18 text-center">
                                                    <option value="" selected>เลือกจังหวัด</option>
                                                    <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                                                    <option value="กระบี่">กระบี่</option>
                                                    <option value="กาญจนบุรี">กาญจนบุรี</option>
                                                    <option value="กาฬสินธุ์">กาฬสินธุ์</option>
                                                    <option value="กำแพงเพชร">กำแพงเพชร</option>
                                                    <option value="ขอนแก่น">ขอนแก่น</option>
                                                    <option value="จันทบุรี">จันทบุรี</option>
                                                    <option value="ฉะเชิงเทรา">ฉะเชิงเทรา</option>
                                                    <option value="ชัยนาท">ชัยนาท</option>
                                                    <option value="ชัยภูมิ">ชัยภูมิ</option>
                                                    <option value="ชุมพร">ชุมพร</option>
                                                    <option value="ชลบุรี">ชลบุรี</option>
                                                    <option value="เชียงใหม่">เชียงใหม่</option>
                                                    <option value="เชียงราย">เชียงราย</option>
                                                    <option value="ตรัง">ตรัง</option>
                                                    <option value="ตราด">ตราด</option>
                                                    <option value="ตาก">ตาก</option>
                                                    <option value="นครนายก">นครนายก</option>
                                                    <option value="นครปฐม">นครปฐม</option>
                                                    <option value="นครพนม">นครพนม</option>
                                                    <option value="นครราชสีมา">นครราชสีมา</option>
                                                    <option value="นครศรีธรรมราช">นครศรีธรรมราช</option>
                                                    <option value="นครสวรรค์">นครสวรรค์</option>
                                                    <option value="นราธิวาส">นราธิวาส</option>
                                                    <option value="น่าน">น่าน</option>
                                                    <option value="นนทบุรี">นนทบุรี</option>
                                                    <option value="บึงกาฬ">บึงกาฬ</option>
                                                    <option value="บุรีรัมย์">บุรีรัมย์</option>
                                                    <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์</option>
                                                    <option value="ปทุมธานี">ปทุมธานี</option>
                                                    <option value="ปราจีนบุรี">ปราจีนบุรี</option>
                                                    <option value="ปัตตานี">ปัตตานี</option>
                                                    <option value="พะเยา">พะเยา</option>
                                                    <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา</option>
                                                    <option value="พังงา">พังงา</option>
                                                    <option value="พิจิตร">พิจิตร</option>
                                                    <option value="พิษณุโลก">พิษณุโลก</option>
                                                    <option value="เพชรบุรี">เพชรบุรี</option>
                                                    <option value="เพชรบูรณ์">เพชรบูรณ์</option>
                                                    <option value="แพร่">แพร่</option>
                                                    <option value="พัทลุง">พัทลุง</option>
                                                    <option value="ภูเก็ต">ภูเก็ต</option>
                                                    <option value="มหาสารคาม">มหาสารคาม</option>
                                                    <option value="มุกดาหาร">มุกดาหาร</option>
                                                    <option value="แม่ฮ่องสอน">แม่ฮ่องสอน</option>
                                                    <option value="ยโสธร">ยโสธร</option>
                                                    <option value="ยะลา">ยะลา</option>
                                                    <option value="ร้อยเอ็ด">ร้อยเอ็ด</option>
                                                    <option value="ระนอง">ระนอง</option>
                                                    <option value="ระยอง">ระยอง</option>
                                                    <option value="ราชบุรี">ราชบุรี</option>
                                                    <option value="ลพบุรี">ลพบุรี</option>
                                                    <option value="ลำปาง">ลำปาง</option>
                                                    <option value="ลำพูน">ลำพูน</option>
                                                    <option value="เลย">เลย</option>
                                                    <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                                                    <option value="สกลนคร">สกลนคร</option>
                                                    <option value="สงขลา">สงขลา</option>
                                                    <option value="สมุทรสาคร">สมุทรสาคร</option>
                                                    <option value="สมุทรปราการ">สมุทรปราการ</option>
                                                    <option value="สมุทรสงคราม">สมุทรสงคราม</option>
                                                    <option value="สระแก้ว">สระแก้ว</option>
                                                    <option value="สระบุรี">สระบุรี</option>
                                                    <option value="สิงห์บุรี">สิงห์บุรี</option>
                                                    <option value="สุโขทัย">สุโขทัย</option>
                                                    <option value="สุพรรณบุรี">สุพรรณบุรี</option>
                                                    <option value="สุราษฎร์ธานี">สุราษฎร์ธานี</option>
                                                    <option value="สุรินทร์">สุรินทร์</option>
                                                    <option value="สตูล">สตูล</option>
                                                    <option value="หนองคาย">หนองคาย</option>
                                                    <option value="หนองบัวลำภู">หนองบัวลำภู</option>
                                                    <option value="อำนาจเจริญ">อำนาจเจริญ</option>
                                                    <option value="อุดรธานี">อุดรธานี</option>
                                                    <option value="อุตรดิตถ์">อุตรดิตถ์</option>
                                                    <option value="อุทัยธานี">อุทัยธานี</option>
                                                    <option value="อุบลราชธานี">อุบลราชธานี</option>
                                                    <option value="อ่างทอง">อ่างทอง</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label for="formFile" class="form-label">ไฟล์รูป (ป้ายภาษี)</label>
                                                <input type="file" class="form-control text-ptt-18" id="input-image-value" placeholder="คลิกเพื่อแนบไฟล์รูป (ป้ายภาษี)" accept="image/*">
                                            </div>
                                            <div>
                                                <label for="formFile" class="form-label">ไฟล์รูป (คู่มือจดทะเบียนรถ)</label>
                                                <input type="file" class="form-control text-ptt-18" id="input-image-value2" placeholder="คลิกเพื่อแนบไฟล์รูป (คู่มือจดทะเบียนรถ)" accept="image/*">
                                            </div>
                                            `,
                                        preConfirm: () => {

                                            const data1Value = document.getElementById('input-tel-value').value;
                                            const data2Value = document.getElementById('input-image-value').files[0];
                                            const data3Value = document.getElementById('input-image-value2').files[0];
                                            const data4Value = document.getElementById('input-tb1-value').value;
                                            const data5Value = document.getElementById('input-tb2-value').value;

                                            if(!data1Value || !data2Value || !data3Value || !data4Value || !data5Value){
                                                Swal.showValidationMessage(`กรุณากรอกข้อมูลและแนบไฟล์รูปให้ครบถ้วน`)
                                            }
                                            return {
                                                data1 : data1Value,
                                                data2 : data2Value,
                                                data3 : data3Value,
                                                data4 : data4Value,
                                                data5 : data5Value
                                            }
                                        }
                                    });
                                    if (formValues) {
                                        if(!formValues.data1){
                                            Swal.fire({
                                                title: 'เกิดข้อผิดพลาด',
                                                text: 'กรุณากรอกเบอร์โทรศัพท์ก่อน',
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: true,
                                                denyButtonText: "ปิด",
                                            }).then((result) => {
                                                if (result.isDenied) {
                                                    Swal.close();
                                                }
                                            });
                                        }else if(!formValues.data2){
                                            Swal.fire({
                                                title: 'เกิดข้อผิดพลาด',
                                                text: 'กรุณาแนบไฟล์รูปป้ายภาษีก่อน',
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: true,
                                                denyButtonText: "ปิด",
                                            }).then((result) => {
                                                if (result.isDenied) {
                                                    Swal.fire.close();
                                                }
                                            });
                                        }else if(!formValues.data3){
                                            Swal.fire({
                                                title: 'เกิดข้อผิดพลาด',
                                                text: 'กรุณาแนบไฟล์รูปคู่มือจดทะเบียนรถก่อน',
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: true,
                                                denyButtonText: "ปิด",
                                            }).then((result) => {
                                                if (result.isDenied) {
                                                    Swal.close();
                                                }
                                            });
                                        }else if(!formValues.data4){
                                            Swal.fire({
                                                title: 'เกิดข้อผิดพลาด',
                                                text: 'กรุณากรอกทะเบียนรถก่อน',
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: true,
                                                denyButtonText: "ปิด",
                                            }).then((result) => {
                                                if (result.isDenied) {
                                                    Swal.close();
                                                }
                                            });
                                        }else if(!formValues.data5){
                                            Swal.fire({
                                                title: 'เกิดข้อผิดพลาด',
                                                text: 'กรุณาเลือกจังหวัดทะเบียนรถก่อน',
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: true,
                                                denyButtonText: "ปิด",
                                            }).then((result) => {
                                                if (result.isDenied) {
                                                    Swal.close();
                                                }
                                            });
                                        }else{
                                            if((formValues.data2.size / 1024 / 1024 <= 3 && formValues.data2.type.split('/')[0] === "image") && (formValues.data3.size / 1024 / 1024 <= 3 && formValues.data3.type.split('/')[0] === "image")){
                                                const img_64_array = await readAsDataURLdata(formValues.data2,formValues.data3); 
                                                const res = await axios.post(`uploadtb`,{
                                                    img_64_1: img_64_array[0],
                                                    img_64_2: img_64_array[1],
                                                    tb1: formValues.data4,
                                                    tb2: formValues.data5,
                                                    idv6: response.data.idv6,
                                                    carnum: response.data.carnum,
                                                    tel: formValues.data1,
                                                });
                                                if(res.data.status == 1){
                                                    Swal.fire({
                                                        title: res.data.title,
                                                        text: res.data.datas,
                                                        icon: "success"
                                                    });
                                                }else if(res.data.status == 2){
                                                    Swal.fire({
                                                        title: 'ไม่สำเร็จ',
                                                        text: res.data.datas,
                                                        icon: "error"
                                                    });
                                                }else if(res.data.status == 3){
                                                    Swal.fire({
                                                        title: 'ไม่สำเร็จ',
                                                        text: res.data.datas,
                                                        icon: "error"
                                                    });
                                                }
                                            }else{
                                                Swal.fire({
                                                    title: "คำเตือน !",
                                                    text: 'รูปที่แนบมามีขนาดเกิน 3 MB หรือไม่ใช่ไฟล์รูปภาพ',
                                                    icon: "error"
                                                });
                                            }
                                        }
                                    }else{
                                        Swal.close();
                                    }
                                })()
                            } else if (result.isDenied) {
                                Swal.close();
                            }
                        });
                    }else if(response.data.status == 5){
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: response.data.datas,
                            icon: "error"
                        });
                    }else if(response.data.status == 6){
                        Swal.fire({
                            title: 'ไม่สำเร็จ',
                            text: response.data.datas,
                            icon: "error"
                        });
                    }else if(response.data.status == 7){
                        Swal.fire({
                            title: response.data.title,
                            text: response.data.datas,
                            icon: "success"
                        });
                    }else if(response.data.status == 8){
                        Swal.fire({
                            title: response.data.title,
                            text: response.data.datas,
                            icon: "success"
                        });
                    }
                } catch (err) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด !',
                        text: err,
                        icon: "error"
                    });
                }
                
            }
        });
    }else{
        Swal.fire({
            title: "ตรวจสอบข้อมูลไม่สำเร็จ",
            text: "กรุณาเปลี่ยนรูปหรือใช้รูปอื่นที่มีความละเอียดชัดเจนมากกว่า หรือติดต่อ  Line OA@PTTNGV  พร้อมแจ้ง \"สแกนข้อมูลไม่สำเร็จ\" ต่อเจ้าหน้าที่",
            icon: "error"
        });
    }
}

const ScanError = ref('')

function onError(err) {
    ScanError.value = `[${err.name}]: `

    if (err.name === 'NotAllowedError') {
        ScanError.value += 'you need to grant camera access permission'
    } else if (err.name === 'NotFoundError') {
        ScanError.value += 'no camera on this device'
    } else if (err.name === 'NotSupportedError') {
        ScanError.value += 'secure context required (HTTPS, localhost)'
    } else if (err.name === 'NotReadableError') {
        ScanError.value += 'is the camera already in use?'
    } else if (err.name === 'OverconstrainedError') {
        ScanError.value += 'installed cameras are not suitable'
    } else if (err.name === 'StreamApiNotSupportedError') {
        ScanError.value += 'Stream API is not supported in this browser'
    } else if (err.name === 'InsecureContextError') {
        ScanError.value +=
        'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
    } else {
        ScanError.value += err.message
    }

    Swal.fire({
        title: "เปิดกล้องไม่สำเร็จ !",
        text: ScanError.value,
        icon: "error"
    });
}
</script>




<template>
    <div class="login-ptt">
        <div class="container">
            <div class="row vh-90">
                <div class="col-sm-10 col-md-8 col-lg-6 col-xl-6 mx-auto d-table h-100">
                    <div class="d-table-cell align-middle">
                        <div class="text-center">
                            <img src="../assets/img/ptt/logo/ptt-cng-qr-logo.png" class="img-fluid-ptt" alt="Unsplash">
                            <p class="text-ptt-white text-ptt-28 text-ptt-shadow"><b>ระบบตรวจสอบและแจ้งปัญหาการใช้งาน QR Code</b></p>
                        </div>
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center mt-3 mb-3">
                            <button @click="openModalDIS(),typeScanDC = 0" class="btn btn-facebook" style="font-size: 22px;" type="button">การเชื่อมต่อข้อมูล QR Code และ บัตรส่วนลด<br>(เฉพาะผู้อยู่ในโครงการเท่านั้น)</button>
                            <button @click="openModalTB(),typeScantb = 0"  class="btn btn-info" style="font-size: 22px;"  type="button">แจ้งปัญหารถรอป้ายและป้ายแดง</button>
                        </div>
                        <div class="text-center mb-3 text-ptt-s1">
                            <span class="text-ptt-bule">สนับสนุนโดย</span> <a href="https://www.pttplc.com" target="_blank" class="text-ptt-white text-ptt-shadow">บริษัท ปตท. จำกัด(มหาชน)</a>
                            <p><span class="text-ptt-bule">ติดต่อสอบถาม</span> <a href="tel:1365" class="text-ptt-white text-ptt-shadow">1365 Contact Center</a> <span class="text-ptt-bule">หรือ</span> <a href="https://page.line.me/PTTNGV?openQrModal=true" target="_blank" class="text-ptt-white text-ptt-shadow">Line OA @PTTNGV</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="model-dis" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <b class="text-ptt-26">สแกน QR Code เพื่อตรวจสอบเชื่อมต่อข้อมูล QR Code <br/>และ บัตรส่วนลด</b>
                            <button type="button" @click="closeModalDIS()" class="close-btn">&times;</button>
                        </div>
                        <div class="modal-body">
                            <div class="col-sm-12 mb-3">
                                <select class="form-select" style="font-size: 18px;" aria-label="Default select example" v-model="typeScanDC">
                                    <option value="0">กรุณาเลือกรูปแบบการส่งข้อมูล</option>
                                    <option value="1">สแกนผ่านกล้อง</option>
                                    <option value="2">แนบไฟล์รูป</option>
                                </select>
                            </div>
                            <qrcode-stream v-if="typeScanDC == 1" @detect="onDetect" @camera-on="onReady" @error="onError" camera="{ width: { min: 60% }, height: { min: 60% } }"></qrcode-stream>
                            <qrcode-capture v-if="typeScanDC == 2" @detect="onDetect" class="form-control" style="font-size: 18px;"></qrcode-capture>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="closeModalDIS()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="model-tb" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">สแกน QR Code เพื่อแจ้งปัญหารถรอป้ายและป้ายแดง</b>
                        <button type="button" @click="closeModalTB()" class="close-btn">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 mb-3">
                            <select class="form-select" style="font-size: 18px;" aria-label="Default select example" v-model="typeScanTB">
                                <option value="0">กรุณาเลือกรูปแบบการส่งข้อมูล</option>
                                <option value="1">สแกนผ่านกล้อง</option>
                                <option value="2">แนบไฟล์รูป</option>
                            </select>
                        </div>
                        <qrcode-stream v-if="typeScanTB == 1" @detect="onDetectTB" @camera-on="onReady" @error="onError" camera="{ width: { min: 60% }, height: { min: 60% } }"></qrcode-stream>
                        <qrcode-capture v-if="typeScanTB == 2" @detect="onDetectTB" class="form-control" style="font-size: 18px;"></qrcode-capture>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="closeModalTB()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style>
.login-ptt {
    width: 100%;
    height: 100vh;
    display: inline-block;
    display: flex;
    align-items: center;
}
.close-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 26px;
}
</style>